





















































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";
import { v4 as uuid } from "uuid";
import useMisc from "@/use/misc";

export default defineComponent({
  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      loaded: false,
      showPassword: false,
      defaultAddress: false,
    });

    const model = reactive({
      type: "withLogin",
      name: "",
      email: "",
      password: "",
      server: "",
      port: 587,
      defaultAddress: false,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const portsList = [25, 465, 587];

    const rules = {
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      password: [(v: string) => !!v || root.$tc("rules.password")],
      server: [
        (v: string) => (!!v && validator.isFQDN(v)) || root.$tc("rules.server"),
      ],
    };

    //  Start fetch single email address data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`mail-account/${root.$route.params.id}`)
        .then(({ data: { mailAccount } }) => {
          model.type = mailAccount.type;
          model.name = mailAccount.name;
          model.email = mailAccount.email;
          model.password = mailAccount.password;
          model.server = mailAccount.server;
          model.port = mailAccount.port;
          model.defaultAddress = mailAccount.defaultAddress;
          state.defaultAddress = mailAccount.defaultAddress;
          state.loaded = true;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single email address data

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        type: model.type,
        name: model.name,
        email: model.email,
        password: model.password || undefined,
        server: model.server || null,
        port: model.port || null,
        defaultAddress: model.defaultAddress || false,
      };

      state.loading = true;

      axiosInstance
        .put(`mail-account/${root.$route.params.id}`, data)
        .then(() => {
          state.success = true;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.globalTools.mailAddresses.edit.success"),
          });
          setTimeout(() => {
            goBack("panel.mailAddress.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      portsList,
      rules,
    };
  },
});
